.table-responsive {
    border: 1px solid #AEAEAE;
    border-radius: 8px;
}
table.table {
    border: 0;
    border-radius: 0;
    margin-bottom: 0;

    thead {
        th {
            background: #E9E9EC;
            text-align: center;
            border: 0;
            border-bottom: 1px solid #AEAEAE;
            font-size: 14px;
            font-weight: 400;
            color: #5C5C5C;
            padding: 15px 30px;
            position: relative;
            white-space: nowrap;

            i.fas.fa-sort-down {
                position: relative;
                right: 9px;
            }

            &>*{
                position: relative;
                z-index: 1;
            }


            // &::after {
            //     content: "";
            //     width: 100%;
            //     height: 100%;
            //     position: absolute;
            //     background: transparent;
            //     left: 0;
            //     top: 0;
            //     border-top: 1px solid #AEAEAE;
            //     z-index: 0;
            // }

            &:first-child {
                    // border-top-left-radius: 8px;

                &::after {
                    // border-left: 1px solid #AEAEAE;
                    // border-top-left-radius: 8px;
                    // left: -1px;
                }
            }

            &:last-child {
                // border-top-right-radius: 8px;

                &::after {
                    // border-right: 1px solid #AEAEAE;
                    // border-top-right-radius: 8px;
                    // right: -1px;
                }
            }


        }
    }

    td {
        text-align: center;
        vertical-align: middle;
        word-break: break-all;
        font-size: 18px;
        padding: 20px 10px;

        &:first-child {
            // border-left: 1px solid #AEAEAE;
        }

        &:last-child {
            // border-right: 1px solid #AEAEAE;
        }
    }

    td {
        border-bottom: 1px solid #D9D9D9;
    }

    tr{
        &:last-child {
            td {
                border-bottom: 0 solid #D9D9D9;
            }
        }
    }

    .btn-outline{
        border: 0.92px solid var(--Neutral-Grey5, #D9D9D9)
    }
}