* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    color: $color-black;
    background-color: $color-white;
    font-family: $font-family;
    min-width: 360px;
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
    margin: 0;
}

.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
    margin: 0;
}

img {
    max-width: 100%;
}

ul {
    padding-left: 0;
    margin-bottom: 0;
}

a,
a:hover {
    text-decoration: none;
    color: inherit;
}

:focus {
    outline: none !important;
}


.z-i-2 {
    z-index: 2 !important;
}

.z-i-3 {
    z-index: 3 !important;
}

.z-i-4 {
    z-index: 4 !important;
}


.align-center {
    display: flex;
    align-items: center;
}



.text-primary {
    color: $primary !important;
}

.wrapper .content-wrapper {
    height: calc(100vh - 94px);
    overflow: auto;
    background-color: white;
}

.content {
    padding: 40px 25px;
}