$color-black: #000000;
$color-dark: #434343;
$color-gray1: #5C5C5C;
$color-gray2: #919191;
$color-gray3: #AEAEAE;
$color-gray4: #D9D9D9;
$color-white: #FFFFFF;


$primary-dark : #1F263E;
$primary : #3A40C5;
$primary-light : #6C72FF;
$primary-light1 : #6A6ED5;
$primary-fade : #DFE0F6;
$primary-fade2 : #E9E9EC;




// Navbar colors
$nav-text: $color-gray3;
$nav-active-bg: rgba($primary-light1, .25);
$nav-seprator: #DFDFDF;