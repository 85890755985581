.main-sidebar {
    background: $primary-dark;
    width: 290px;

    .brand-link {
        padding: 35px 30px 30px;
        width: 100%;
        height: 94px;
        display: flex;
        align-items: center;
    }

    &[class*=sidebar-dark] .brand-link {
        border-bottom: 1px solid $nav-seprator;
    }

    .sidebar {
        padding: 0 16px 16px;
    }

    .sub-head {
        font-size: 12px;
        color: rgba($color-white, .3);
        padding-top: 26px;
        padding-left: 18px;
        padding-bottom: 16px;
        text-transform: uppercase;
    }

    .nav-sidebar .nav-item>.nav-link {
        margin-bottom: 0;
        width: 100%;
    }

    .nav-items {
        padding: 0;

        .nav-link {
            display: flex;
            align-items: center;
            width: 100%;

            img {
                margin-right: 12px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: $nav-text;
            }


        }

        .dropdown-item {
            margin-bottom: 15px;

            .nav-link.dropdown-link {
                padding: 13px 18px;
                border: 0.5px solid var(--Neutral-Grey4, #AEAEAE);

                .company-title {
                    font-size: 10px;
                    color: #D9D9D9;
                    font-weight: 400;
                }

                .company-name {
                    font-size: 14px;
                    color: #FFFFFF;
                    font-weight: 400;
                }
                p{
                    color: white;
                }

                &:after{
                    width: 10px;
                    height: 20px;
                    content: "";
                    // background: ../;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                &:hover {
                    color: $color-white;
                    background-color: $nav-active-bg;
                    border-radius: 6px;


                    &::after {
                        display: none;
                    }
                }
            }
        }

    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link,
    .nav-sidebar .nav-item>.nav-link:hover {
        color: $color-white;
        background-color: $nav-active-bg;
        border-radius: 0px 6px 6px 0px;
        position: relative;

        p {

            color: $color-white;
            font-weight: 500;
        }

        &::after {
            content: "";
            width: 5px;
            height: 100%;
            background-color: $primary-light1;
            position: absolute;
            border-radius: 6px 0 0 6px;
            left: 0;
            top: 0;

        }
    }
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
    width: calc(290px - .5rem* 2);
    transition: width ease-in-out .3s;
}


.sidebar-collapse .main-sidebar,
.sidebar-collapse .main-sidebar::before {
    margin-left: -290px;
}

@media (min-width: 992px) {

    .sidebar-mini.sidebar-collapse .main-sidebar,
    .sidebar-mini.sidebar-collapse .main-sidebar::before {
        margin-left: 0;
        width: 4.6rem;
    }
}

@supports not (-webkit-touch-callout:none) {
    .layout-fixed .wrapper .sidebar {
        height: calc(100vh - (5.5rem + 1px));
    }
}