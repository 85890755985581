.mat-mdc-form-field-type-mat-select {

    .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
        height: 0;
    }
}

.select-outline-white {
    height: 44px;
    border: 1px solid #919191;
    border-radius: 3px;
    width: 100%;
}

.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
    border: 1px solid #919191;
}

button.dropdown-toggle::after {
    content: none;
}


.search-container {
    display: block;
    width: 100%;
    height: 44px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #919191;
    appearance: none;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #919191;
    border-radius: 6px;
    overflow: hidden;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:focus-within {
        color: var(--bs-body-color);
        background-color: var(--bs-body-bg);
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }

    label {
        margin: 0 12px;
    }

    .form-control {
        border: none;
        padding: 0;
        height: 100%;

        &:focus {
            color: var(--bs-body-color);
            background-color: none;
            border-color: none;
            outline: 0;
            box-shadow: none;
        }
    }
}

.status-checkbox {
    position: relative;

    input {
        border: none;
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 0;

        &:checked {
            &~.checked {
                display: block;
            }

            &~.unchecked {
                display: none;
            }
        }

        &~.checked {
            display: none;
        }

        &~.unchecked {
            display: block;
        }
    }
}

.form-control {
    border: 0.6px solid var(--Neutral-Grey3, #919191);
    border-radius: 4px;
    height: 44px;
}


//swith slider
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;


    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

input:checked+.slider {
    background-color: $primary;
}

input:focus+.slider {
    box-shadow: 0 0 1px $primary;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

}



input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


//swith slider



label.label{
    font-weight: 400;
    font-size: 16px;
    color: #434343;
}