app-header.main-header {
    padding: 25px 40px;
    border-bottom: 1px solid #AEAEAE;


    .navbar-nav {
        align-items: center;

        &.right-menu li.nav-item {
            margin: 0 10px;
        }
    }

    .menu-icon {
        padding: 0 !important;
        height: auto;
    }

    .page-title {
        margin: 0;
        font-size: 24px;
        font-weight: 500;
        margin-left: 24px;
    }
}

@media (min-width: 768px) {

    body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
    body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
    body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
        margin-left: 290px;
    }
}