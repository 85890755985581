.modal {
    .modal-title {
        margin-bottom: 0 !important;
        font-size: 20px;
        font-weight: 500;
        color: black;

        &::before {
            content: "<";
            margin-right: 10px;
            font-family: monospace;
            font-size: 26px;
            font-weight: 700;
        }
    }

    .btn-close {
        opacity: 1;
    }


    .modal-footer {
        border-top: 0;
        padding-top: 0;
        display: flex;

        button.btn {
            flex: 1;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }

            &.btn-primary {
                font-weight: 400;
            }

        }
    }
}