.mat-mdc-tab-labels {
    // border: 0.6px solid var(--Neutral-Grey3, #919191);
    // border-radius: 4px;
}

.mat-mdc-tab {
    border: 1px solid #919191;
    border-width: 1px 0 1px 1px;

    &:first-child {
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-width: 1px;
        border-radius: 0 4px 4px 0;
    }

    &.mdc-tab--active {
        // border: 0;
    }

    .mdc-tab-indicator__content--underline {
        border-radius: 4px;
        background: #DFE0F6;
        height: 100%;
        border: 0;
    }
}