.group-card {
    border-radius: 9px;
    border: 0.6px solid #919191;
    padding: 18px 12px;

    .group-head {
        display: flex;
        padding-bottom: 15px;
        border-bottom: 0.56px solid #AEAEAE;

        .group-title {
            display: flex;
            align-items: center;
            margin-right: auto;

            img {
                width: 40px;
            }

            .group-desc {
                margin-left: 10px;
            }
        }
    }

    .group-desc {
        margin: 0 min(20px, 1.04vw);

        .label {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0;
            color: #919191;
        }

        .title {
            font-size: 18px;
            font-weight: 500;
            margin: 0;
            color: #000000;
        }

        .text {
            font-size: 18px;
            font-weight: 400;
            margin: 0;
            color: #000000;
        }
    }

    .group-content {
        padding-top: 10px;
        display: flex;
        align-items: center;

        .group-desc {
            .label {
                font-size: 10px;
                text-transform: capitalize;
                margin-bottom: 10px;

                &.active,
                &.inactive {
                    padding-left: 10px;
                    position: relative;

                    &:after{
                        content: "";
                        width: 5px;
                        height: 5px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        border-radius: 20px;
                        background-color: #5AD967;
                    }
                }
                &.inactive:after {
                    background-color: #F64343;
                }
                
            }
        }

        .cta-link {
            margin-left: auto;
            font-size: 14px;
            color: #3A40C5;
            text-decoration: none;
        }
    }
}