.design-v2 {

    @import "./base/colors";
    @import "./base/fonts";
    @import "./base/base";

    @import "./module/buttons";
    @import "./module/forms";
    @import "./module/modals";
    @import "./module/tabs";

    @import "./components/sidebar";
    @import "./components/navbar";
    @import "./components/cards";
    @import "./components/table";
}