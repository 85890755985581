@mixin btn {
    padding: 6px 14px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 1px soild inherit;
    border-radius: 6px;
    height: 44px;
    white-space: nowrap;
    transition: .2s;
}

.btn {
    @include btn;

    &.btn-lg{
        min-width: 200px;
    }
    span{
        display: flex;
        align-items: center;

        img{
            margin-right: 6px;
        }
    }

    &.btn-gray {
        background-color: $primary-fade2;
        border-color: $primary-fade2;
        color: $primary-dark;

        &:hover,
        &:focus {
            background-color:  $primary-dark;
            color: $primary-fade2;

            img {
                filter: brightness(40);
            }
        }
    }

    &.btn-primary {
        background-color: $primary;
        color: $color-white;
        border-color: $primary;
        font-weight: 500;

        &:hover,
        &:focus {
            background-color: $color-white;
            color: $primary;
        }
    }

    &.btn-outline-primary {
        background-color: $color-white;
        color: $primary;
        border-color: $primary;
        font-weight: 500;

        &:hover,
        &:focus {
            background-color: $primary;
            color: $color-white;
        }
    }

}